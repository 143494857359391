<template>

<div style="padding: 40px;">
<p class="P23"><span class="T1">平台隐私政策</span><span class="T2"/></p>
<p class="P24"> </p>
<p class="P9"><span class="T3"> </span><span class="T6"/></p>

<p class="P1"><a id="_Hlk84923656"/><span class="Strong"><span class="T8">【引言】</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施，致力于保护您的个人信息安全可控。基于此，看看云医平台服务提供者（或简称“</span><span class="Strong"><span class="T8">我们</span></span><span class="T8">”）制定本《看看云医平台隐私政策》（下称“本政策”/“本隐私政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。</span><span class="T6"/></p>

<p class="P10"><span class="T8">在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以</span><span class="Strong"><span class="T10">粗体/粗体下划线</span></span><span class="T8">标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。</span><span class="Strong"><span class="T8">如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。</span></span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</span></span><span class="T6"/></p>

<p class="P2"><span class="Strong"><span class="T12"> </span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">本隐私政策将帮助您了解以下内容：</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">一、适用范围</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">二、信息收集及使用</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">三、对外提供信息</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">四、您的权利</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">五、信息的存储</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">六、政策的更新</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">七、未成年人保护</span></span><span class="T6"/></p>

<p class="P3"><span class="Strong"><span class="T8">八、联系我们</span></span><span class="T6"/></p>

<p class="P4"><span class="Strong"><span class="T12">附录：相关定义</span></span><span class="T6"/></p>

<p class="P4"><span class="Strong"><span class="T12"> </span></span><span class="T6"/></p>

<p class="P11"><span class="Strong"><span class="T8">一、适用范围</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">本政策适用于看看云医平台服务提供者以网站、移动客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。</span><span class="Strong"><span class="T8">我们及关联公司就其向您提供的产品或服务单独设有隐私政策的，则相应产品或服务适用相应隐私政策。</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">除本政策说明的相关信息收集使用活动外，本政策不适用于平台商家及其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。</span><span class="T6"/></p>

<p class="P11"><span class="Strong"><span class="T8">二、信息收集及使用</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">在您使用我们的产品/服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</span><span class="T6"/></p>

<p class="P10"><span class="T8">1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。</span><span class="Strong"><span class="T8">如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。</span><span class="Strong"><span class="T8">如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求。</span><span class="Strong"><span class="T8">因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准。</span></span><span class="T6"/></p>

<p class="P13"><span class="T12">我们会为您提供的各项具体功能场景包括：</span><span class="T6"/></p>

<p class="P14"><span class="Strong"><span class="T3">（一）帮助您成为我们的注册用户及账户管理</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">1、为向您提供账户注册、登录功能及服务，在您通过以下方式注册或登录看看云医时，您需要提供如下账户注册信息：</span><span class="T6"/></p>

<p class="P10"><span class="T8">一键登录：当您选择使用手机号码登录方式登录时，您需要输入手机号码及对应验证码，经验证通过后方可登录。我们会收集您所填写的</span><span class="Strong"><span class="T8">手机号码</span></span><span class="T8">，用于账户管理及登录验证。</span><span class="T6"/></p>

<p class="P10"><span class="T8">看看云医账户登录：您需要向我们提供您的账户名及对应的密码以验证您的身份后登录。如果您没有看看云医账户，可通过手机号码并创建密码的方式注册账户。当您使用手机号码注册时，我们会向您的</span><span class="Strong"><span class="T8">手机号码</span></span><span class="T8">发送短信验证码，您需要向我们提供</span><span class="Strong"><span class="T8">短信验证码</span></span><span class="T8">以验证您的身份后方可成功注册。</span><span class="T6"/></p>

<p class="P5"><span class="T8">第三方账户登录：您可以使用看看云医授权的</span><span class="T11">第三方</span><span class="Strong"><span class="T8">账户</span></span><span class="T8">登录，当您授权使用前述第三方账户登录时，</span><span class="T3">我们可能会根据您的授权从第三方获取</span><span class="Strong"><span class="T5">您的第三方账户信息（</span></span><span class="Strong"><span class="T10">昵称、头像、手机号及其他您在第三方授权登录界面同意授予的信息</span></span><span class="Strong"><span class="T5">）</span></span><span class="T3">，并与您的看看云医账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信息。如果您需要解除该第三方账户绑定的，请您联系我们的客服人员进行操作。</span><span class="T6"/></p>

<p class="P5"><span class="T8">2、如果您已拥有看看云医账户，我们可能会在看看云医中显示您的上述个人信息，以及您在看看云医中的相关设置，包括通过看看云医账户展示</span><span class="Strong"><span class="T8">您的头像、个人资料、绑定手机号、绑定第三方情况</span></span><span class="T8">等。</span><span class="T6"/></p>

<p class="P5"><span class="T3">3、如您不提供上述信息，不注册账户，将不会影响您使用看看云医提供的浏览和搜索服务，但可能会影响您使用看看云医的如下功能：无法使用问诊、购买药品、</span><span class="T13">就医挂号、检查服务、检测服务、健康咨询或测评服务、报告查询</span><span class="T3">，无法使用评论功能，无法参与相关运营活动。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（二）</span></span><span class="Strong"><span class="T8">向您展示商品或服务信息</span></span><span class="T6"/></p>

<p class="P16"><span class="Strong"><span class="T8">为向您展示商品或服务信息，包括您的访问足迹、历史搜索情况，我们会收集和使用您在访问或使用看看云医平台网站或客户端时的浏览、搜索记录。我们会结合依法收集的设备信息、服务日志信息，以及其他取得您授权的信息，通过算法模型预测您的偏好特征，匹配您可能感兴趣的商品、服务或其他信息，对向您展示的商品、服务或其他信息进行排序。为满足您的多元需求，我们会在排序过程中引入多样化推荐技术，拓展推荐的内容，避免同类型内容过度集中。我们也会基于您的偏好特征在看看云医平台及其他第三方应用程序向您推送您可能感兴趣的商业广告及其他信息，或者向您发送商业性短信息。</span></span><span class="T6"/></p>

<p class="P16"><span class="T8">同时，我们通过以下措施努力保障您的隐私体验：</span><span class="T6"/></p>

<p class="P16"><span class="T8">1、如您要删除您的浏览和搜索记录，您可以选择删除；</span><span class="T6"/></p>

<p class="P16"><span class="T8">2、如您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。</span><span class="T7"/></p>

<p class="P15"><span class="Strong"><span class="T3">（三）</span></span><span class="Strong"><span class="T8">为您提供医疗健康类服务及周边配套服务</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以享受看看云医服务提供者及供应商、服务提供商和其他合作伙伴（下称“第三方合作机构”）提供的预约挂号、预约体检、药房、在线问诊及复诊开药业务等服务医疗健康类服务或周边的配套服务（具体的服务内容及服务主体以产品页面的展示为准）。</span><span class="T6"/></p>

<p class="P5"><span class="T3">在您使用看看云医的上述</span><span class="T8">服务</span><span class="T3">过程中，我们可能会申请开启</span><span class="Strong"><span class="T5">您的位置信息、相机/摄像头、麦克风（语音）以及存储的相关权限</span></span><span class="T3">；同时可能要求您提供</span><span class="Strong"><span class="T5">您的手机号码、患者姓名、身份证件号码（用于挂号就诊等实名认证）、性别、出生日期、与患者的关系、身高体重、病症、门诊/住院病历、诊治情况、检验报告、手术及麻醉记录、用药记录、药物食物过敏信息、生育信息（女性患者）、以往病史、家族病史、现病史、疫苗接种信息及其他个人健康相关信息，并可能需要看看云医服务提供者将上述信息提供给到为您实际提供服务的具体服务提供者（如第三方合作机构）</span></span><span class="T3">，以便该服务提供者直接为您提供服务。另外，我们可能会收集您在使用看看云医过程中产生的</span><span class="Strong"><span class="T3">健康咨询详情、问诊记录、预约挂号记录、检查检验报告、用药处方、购药订单、医生诊断结果、体检报告等信</span></span><span class="T3">息，用于向您展示及便于您对信息进行管理。</span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以通过看看云医为其他人进行预约或咨询等服务，您需要提供该实际用户的前述个人信息。在提供该实际用户的前述个人信息之前，您需确保您已经取得其授权同意，其中涉及儿童个人信息的，您需在提供前征得该儿童监护人的同意。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">（四）为您提供收藏、加购、关注与分享功能</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">在您浏览我们网站或客户端的过程中，您可以选择对感兴趣的商品及/或服务进行收藏、添加至购物车、与您感兴趣的商家/品牌/其他用户建立关注关系、通过我们提供的功能组件向第三方分享商品/服务信息。在您使用上述功能的过程中，我们会收集包括您的收藏及添加购物车的记录、关注关系、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。</span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以</span><span class="T8">登录看看云医APP，进入“我的”页面，点击头像后，您可以查看、管理或删除您的收藏和关注信息。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">（五）</span></span><span class="Strong"><span class="T8">帮助您完成下单和订单管理</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的</span><span class="Strong"><span class="T3">收货人姓名、收货地址、收货人联系电话</span></span><span class="T3">，对于部分特殊类型的商品和服务您还需要提供该商品或服务所必须的其他信息。</span><span class="T6"/></p>

<p class="P5"><span class="T3">同时该订单中会载明您所购买的商品及/或服务信息、具体订单号、订单创建时间、您应支付的金额、您的备注信息，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。</span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以通过看看云医为其他人订购商品及/或服务，您需要提供该实际订购人的前述个人信息。</span><span class="Strong"><span class="T5">其中涉及儿童订购的，您需在订购前取得所涉及儿童的监护人授权同意。</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">为便于您了解查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。 </span><span class="T6"/></p>

<p class="P5"><span class="T3">您可额外填写/选择包括</span><span class="Strong"><span class="T3">其他联系电话</span></span><span class="T3">、收货时间在内的更多附加信息以确保商品或服务的准确送达。</span><span class="T6"/></p>

<p class="P5"><span class="T8">登录看看云医APP，进入“我的”页面，您可以查看和管理您的问诊订单、购药订单、挂号记录。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（六）</span></span><span class="Strong"><span class="T8">帮助您完成支付 </span></span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T10">为完成订单支付，您需要提供微信、支付宝等账户并选择付款方式，我们会将您的看看云医账户、对应的微信、支付宝等账户名、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在网站或移动客户端中嵌入的微信、支付宝软件工具开发包（软件工具开发包在本政策中简称“SDK”）等方式与微信、支付宝等共享。如您选择由其他金融机构为您提供支付服务，我们还会将您的包括银行卡号、有效期在内的银行卡支付必要信息与您选择的相应金融机构共享。</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以请求其他人为您付款，那么您需要提供</span><span class="Strong"><span class="T3">代付人的</span></span><span class="Strong"><span class="T10">微信、支付宝等</span></span><span class="Strong"><span class="T3">账户及/或手机号码</span></span><span class="T3">。</span><span class="T6"/></p>

<p class="P5"><span class="T3">为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象、微信、支付宝或您选择的其他金融机构处收集与支付进度相关信息。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（七）</span></span><span class="Strong"><span class="T8">帮助向您完成商品或服务的交付</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">根据您与商品及/或服务提供主体的约定，相关配送服务主体（包括商家或第三方配送服务提供商）将为您完成订单的交付。您知悉并同意相应配送服务主体不可避免地获知及使用您的</span><span class="Strong"><span class="T3">订单相关配送信息（如：收货人姓名、收货地址、收货人联系电话）</span></span><span class="T3">，以保证您购买/申请的商品及/或服务能够顺利、安全、准确送达。</span><span class="T6"/></p>

<p class="P5"><span class="T3">为使我们及时获悉并确认交付进度及状态，为您提供售后及争议解决服务，您同意我们可自您选择的商品及/或服务提供主体、配送服务主体处收集与交付进度相关的信息。</span><span class="T6"/></p>

<p class="P12"><span class="Strong"><span class="T3">   （八）</span></span><span class="Strong"><span class="T8">为您提供信息发布和互动服务</span></span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">我们会采取符合业界标准、合理可行的安全防护措施保护您在平台中医患问答信息；同时，为了帮助更多的用户获取医疗健康的知识，您与医生的医患问答信息（含问诊和复诊开方中的信息等）将在严格的个人身份匿名化处理后，展示给其他第三方进行浏览和阅读。同时，我们可能会对个人身份匿名化处理的医患问答信息通过算法分析等方式进行数据利用。</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">您可以在看看云医发表评论、发布图文/视频内容、或参与话题活动，并关注、添加收藏、分享等。因这类功能涉及公共板块，我们将向所有用户公开展示您的</span><span class="Strong"><span class="T3">昵称、头像、性别、您账户发布的内容</span></span><span class="T3">等。</span><span class="T6"/></p>

<p class="P5"><span class="T3">在使用社区社群、用药查询、体检报告解读等服务的过程中，当您使用扫描二维码、拍摄照片、拍摄视频功能时，我们将访问</span><span class="Strong"><span class="T3">您的设备相机、设备麦克风和录音相关权限，并收集您提供的基于扫描二维码、拍摄照片、拍摄视频后向我们上传的图片、视频信息及语音、语音交互信息。</span></span><span class="T3">如您拒绝提供该权限的，仅会使您无法使用该功能，但并不影响您正常使用看看云医平台的其他功能。如果您拒绝提供上述权限的授权，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的信息，否则您的取消行为不会影响我们基于您之前的授权进行的信息的处理、存储。</span><span class="T6"/></p>

<p class="P5"><span class="T3">我们可能会根据您的网络日志信息判断您是否可享受对应功能权限（例如只有问诊后的用户才可以对相应的医生进行评价）。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及他人个人信息的，您需在发布前征得他人的同意。</span></span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">（九）</span></span><span class="Strong"><span class="T8">帮助您获取活动权益</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">看看云医会不时发起运营活动，如果您参加相关运营活动，我们会通过运营活动界面收集包括但不限于</span><span class="Strong"><span class="T5">您的身份信息、联系方式、联系地址</span></span><span class="T3">，以便与您取得联系、核验您的奖励获取资格、向您发放运营奖励等。若您不参加相关运营活动则无需提供前述信息，若您参加运营活动但拒绝提供，您将无法获得相关奖励。</span><span class="T6"/></p>

<p class="P5"><span class="T3">若您获取的活动奖品需由第三方提供，我们会向该第三方披露</span><span class="Strong"><span class="T3">您的配送信息</span></span><span class="T3">，并由其选择向相应的物流配送主体同步相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用</span><span class="Strong"><span class="T3">您的配送信息</span></span><span class="T3">，用于完成交付目的。</span><span class="T6"/></p>

<p class="P5"><span class="T3">为向您提供售后与争议解决服务之目的，我们需要及时获悉并确认交付进度及状态，您同意我们可自第三方及/或物流相关服务主体处收集与交付进度相关的信息。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（十）</span></span><span class="Strong"><span class="T8">客服及争议处理</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">当您与我们联系或提出售中、售后、争议纠纷处理申请时，为了保障您的账户及系统安全，</span><span class="Strong"><span class="T5">我们需要您提供必要的个人信息以核验您的身份</span></span><span class="T3">。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T5">为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账户信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账户信息和订单信息。</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（十一）</span></span><span class="Strong"><span class="T8">为您提供安全保障</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或看看云医平台相关协议、规则的情况，我们可能使用或整合您的注册信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息（其中，我们可能会在应用后台运行期间收集MAC地址、IMEI等设备相关信息进行风险校验），来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span><span class="T6"/></p>

<p class="P15"><span class="Strong"><span class="T3">（十二）</span></span><span class="Strong"><span class="T8">为您提供其他附加服务</span></span><span class="T6"/></p>

<p class="P6"><span class="Strong"><span class="T3">1、基于系统权限的附加服务</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用看看云医平台的基本服务，但您可能无法获得这些附加服务给您带来的用户体验。您可自行决定这些权限随时的开启或关闭。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T3">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span></span><span class="T3"/></p>

<p class="P7"><span class="Strong"><span class="T8">   2、Cookie和同类技术的服务</span></span><span class="T6"/></p>

<p class="P5"><span class="T3">Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符（以下简称“Cookie”），以收集、标识和存储您访问、使用本产品时的信息。我们承诺，不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie 主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复您填写表单、输入搜索内容的步骤和流程。</span><span class="T6"/></p>

<p class="P5"><span class="T3">同时，我们可能会利用 Cookie 向您展示您可能感兴趣的信息或功能，并优化您对广告的选择。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。</span><span class="T6"/></p>

<p class="P7"><span class="Strong"><span class="T3">三、对外提供信息</span></span><span class="T6"/></p>

<p class="P6"><span class="Strong"><span class="T3">（一）共享</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">我们不会与看看云医服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span><span class="T6"/></p>

<p class="P5"><span class="T8">1、</span><span class="Strong"><span class="T8">履行法定义务所必需的共享</span></span><span class="T8">：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</span><span class="T6"/></p>

<p class="P5"><span class="T8">2、</span><span class="Strong"><span class="T8">取得您同意的情况下共享</span></span><span class="T8">：获得您的明确同意后，我们会与其他方共享您的个人信息。</span><span class="T6"/></p>

<p class="P5"><span class="T8">3、</span><span class="Strong"><span class="T8">为订立、履行您作为一方当事人的合同所必需的情况下共享</span></span><span class="T8">：您通过看看云医购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易、配送及售后服务需求。</span><span class="T6"/></p>

<p class="P5"><span class="T8">4、</span><span class="Strong"><span class="T8">与关联公司间共享：</span></span><span class="T8">为便于我们与关联公司共同向您提供服务，</span><span class="Strong"><span class="T10">基于看看云医账户向您提供产品和服务，推荐您可能感兴趣的信息，识别用户账户异常，保护看看云医服务提供者及其关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享</span></span><span class="T8">。我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T8">请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</span></span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T8">（二）委托处理</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。</span><span class="T6"/></p>

<p class="P5"><span class="T8">目前，我们的授权合作伙伴包括以下类型：</span><span class="T6"/></p>

<p class="P5"><span class="T8">1、广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。</span><span class="T6"/></p>

<p class="P5"><span class="T8">2、供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。</span><span class="T6"/></p>

<p class="P5"><span class="T8">为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。授权合作伙伴单独取得您同意的除外。</span><span class="T6"/></p>

<p class="P15"><span class="T3">您可以查看</span><span class="T8">我们接入的SDK详情。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">（三）转让</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">（四）公开披露</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">我们仅会在以下情况下，公开披露您的个人信息：</span><span class="T6"/></p>

<p class="P5"><span class="T8">1、基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；</span><span class="T6"/></p>

<p class="P5"><span class="T8">2、如果我们确定您出现违反法律法规或严重违反看看云医平台相关协议及规则的情况，或为保护看看云医平台用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及看看云医平台已对您采取的措施。</span><span class="T6"/></p>

<p class="P7"><span class="Strong"><span class="T3">四、您的权利</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">您可以通过以下方式查阅和管理您的信息，</span><span class="Strong"><span class="T8">我们会在符合法律法规要求的情形下响应您的请求</span></span><span class="T8">：</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">（一）查阅、更正和补充</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">您有权通过以下方式查阅、更正和补充您的信息：</span><span class="T6"/></p>

<p class="P5"><span class="T8">1、登录看看云医APP，进入“我的”页面，可以查询、修改头像、昵称、性别、地区、手机号码等个人账户相关信息；</span><span class="T6"/></p>

<p class="P5"><span class="T8">2、登录看看云医APP，进入“我的”页面，可以查询、修改、补充、删除您的家人档案。</span><span class="T6"/></p>

<p class="P5"><span class="T8">3、登录看看云医APP，进入“我的”页面，您可以查询您的问诊订单、购药订单、挂号记录等。</span><span class="T6"/></p>

<p class="P5"><span class="T8">4、登录看看云医APP，进入“我的”页面，并选择“联系客服”，协助您查询、更正或补充您的信息。</span><span class="T6"/></p>

<p class="P17"><span class="T8">  </span><span class="Strong"><span class="T8">当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</span></span><span class="T6"/></p>

<p class="P18"><span class="Strong"><span class="T8">（三）改变授权范围</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">对于您无法直接通过上述方式设置的授权，您可以通过与客服联系进行修改，</span><span class="Strong"><span class="T10">但请注意，对于部分类型的个人信息，例如实现看看云医平台基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求。</span></span><span class="T8">当您收回授权后，我们将不再处理相应的个人信息。但您收回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（四）注销账户</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">1、登录看看云医APP并选择联系客服，协助您申请注销您的看看云医账户。</span><span class="T6"/></p>

<p class="P19"><span class="T8">在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除您的个人信息，或对其进行匿名化处理。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（五）约束信息系统自动决策</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">在某些业务功能中，我们可能仅依据算法在内的非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（六）响应您的上述请求</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或直接向我们的个人信息保护专职部门发起投诉。我们将在15天内做出答复。</span><span class="T6"/></p>

<p class="P19"><span class="T8">为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。</span><span class="T6"/></p>

<p class="P19"><span class="T8">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</span><span class="T6"/></p>

<p class="P7"><span class="Strong"><span class="T3">五、信息的存储</span></span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（一）存储期限</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如履行《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年的义务，或依据《中华人民共和国民法典》中向人民法院请求保护民事权利的诉讼时效期间为三年的要求履行义务。</span><span class="T6"/></p>

<p class="P19"><span class="T8">我们判断个人信息的存储期限主要依据以下标准：</span><span class="T6"/></p>

<p class="P19"><span class="T8">1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</span><span class="T6"/></p>

<p class="P19"><span class="T8">2、保证我们为您提供服务的安全和质量；</span><span class="T6"/></p>

<p class="P19"><span class="T8">3、您是否同意更长的留存期间；</span><span class="T6"/></p>

<p class="P19"><span class="T8">4、是否存在关于保留期限的其他特别约定或法律法规规定，例如病历的相关存储期限要求等。</span><span class="T6"/></p>

<p class="P19"><span class="T8">在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（二）存储位置</span></span><span class="T6"/></p>

<p class="P19"><span class="T8">我们在中华人民共和国境内运营中收集和产生的个人信息，将</span><span class="Strong"><span class="T8">存储在中国境内</span></span><span class="T8">。以下情形下，我们会在履行法律规定的义务后，向可能的境外实体提供您的个人信息：</span><span class="T6"/></p>

<p class="P19"><span class="T8">1、适用的法律有明确规定；</span><span class="T6"/></p>

<p class="P19"><span class="T8">2、获得您的单独同意；</span><span class="T6"/></p>

<p class="P19"><span class="T8">3、您作为一方当事人的跨境电子商务交易及其他类型合同订立和履行所必需的。</span><span class="T6"/></p>

<p class="P19"><span class="T8">针对以上情形，我们会按照本政策依法保护您的个人信息安全。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">（三）存储安全</span></span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">1、数据保护技术措施</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护，我们同时对看看云医APP提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">2、数据保护管理组织措施</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性。我们已经设置了个人信息保护专职部门。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">3、个人信息安全事件的响应</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</span><span class="T6"/></p>

<p class="P20"><span class="Strong"><span class="T8">4、账户安全风险的防范</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">在使用看看云医平台进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。此外，您也可以通过我们的服务与第三方建立沟通、交流信息或分析内容。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。</span><span class="T6"/></p>

<p class="P5"><span class="T8">如您担心自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络看看云医客服，以便我们根据您的申请采取相应措施。</span><span class="T6"/></p>

<p class="P7"><span class="Strong"><span class="T3">六、政策的更新</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会</span><span class="T9">在专门页面</span><span class="T8">上发布对本政策所做的任何变更。</span><span class="T6"/></p>

<p class="P10"><span class="T8">对于重大变更，我们还会提供更为显著的通知（包括我们会通过看看云医平台公示的方式进行通知或向您提供弹窗提示）。</span><span class="T6"/></p>

<p class="P18"><span class="T8">本政策所指的重大变更包括但不限于：</span><span class="T6"/></p>

<p class="P10"><span class="T8">1、产品的业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</span><span class="T6"/></p>

<p class="P10"><span class="T8">2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式；</span><span class="T6"/></p>

<p class="P10"><span class="T8">3、个人信息共享或公开披露的主要对象发生变化；</span><span class="T6"/></p>

<p class="P10"><span class="T8">4、用户个人信息权利及其行使方式发生重大变化；</span><span class="T6"/></p>

<p class="P10"><span class="T8">5、处理个人信息保护负责人的联络方式及投诉渠道发生变化时；</span><span class="T6"/></p>

<p class="P10"><span class="T8">6、个人信息保护影响评估报告表明产品存在对个人权益有重大影响时。</span><span class="T6"/></p>

<p class="P21"><span class="T8">我们还会将本政策的旧版本在</span><a href="https://rule.alihealth.cn/rule/list/1089" class="Internet_20_link"><span class="Internet_20_link"><span class="T14">专门页面</span></span></a><span class="T8">存档，供您查阅。</span><span class="T6"/></p>

<p class="P12"><span class="Strong"><span class="T3">七、未成年人保护</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">我们推定您具有相应的民事行为能力。如您为儿童，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T8">如果您是儿童用户的监护人，请您仔细阅读和选择是否同意本政策。为了更好地保护儿童的权益，我们还可能向您收集更多信息（例如姓名、身份证、户口本或其他监护关系证明），以便进一步验证您与儿童的监护关系，并按照法律法规的规定，在获得您的同意之后才允许儿童继续使用看看云医的相关服务。如果您不同意本政策的内容，请您要求您监护的儿童立即停止访问/使用看看云医的服务。</span></span><span class="T8">如您对您监护儿童的个人信息有疑问，请通过本政策第十条中的联系方式与我们联系。</span><span class="T6"/></p>

<p class="P5"><span class="T8">您在使用我们的产品或服务中主动向我们提供您监护儿童的个人信息，例如您在我们为您提供的评论、社区及其他信息发布功能中分享的您监护儿童的照片、视频或其他信息；您在疫苗预约、线上问诊、体检预约服务等功能中提交您监护儿童的个人信息，表示您已经同意我们收集和按照本政策的内容使用您监护儿童的个人信息。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T8">对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息，并将根据本政策采取特殊措施保护我们获得的您监护的儿童的个人信息，具体如下：</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">1、如果为了本政策所述目的而需要将儿童个人信息共享至第三方，我们将评估该第三方收集儿童个人信息的合法性、正当性、必要性，并在征得您的同意或确认第三方已经征得您同意后，要求第三方对儿童个人信息采取保护措施，并且严格遵守相关法律法规与监管要求。我们会要求接收儿童个人信息的第三方遵守严格的保密义务及采取有效的保密措施，禁止其将这些儿童个人信息用于未经儿童及其监护人授权的用途，并要求受托公司依法履行以下义务：</span><span class="T6"/></p>

<p class="P10"><span class="T8">（1）按照法律、行政法规的规定和我们的要求处理儿童个人信息；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（2）协助我们回应儿童监护人提出的申请；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（3）采取措施保障信息安全，并在发生儿童个人信息泄露安全事件时，及时向我们反馈；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（4）委托关系解除时及时删除儿童个人信息；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（5）不得转委托；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（6）其他依法应当履行的儿童个人信息保护义务。</span><span class="T6"/></p>

<p class="P10"><span class="T8">2、我们非常重视儿童的隐私安全，并采取一切合理可行的措施，保护儿童个人信息。</span><span class="T6"/></p>

<p class="P10"><span class="T8">（1）</span><span class="Strong"><span class="T15">我们会严格控制儿童个人信息的访问权限</span></span><span class="T15">，对可能接触到儿童个人信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童个人信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。</span><span class="T6"/></p>

<p class="P10"><span class="T8">（2）</span><span class="Strong"><span class="T15">我们已制定儿童个人信息安全事件应急预案，</span></span><span class="T15">我们会定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童个人信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等</span><span class="Strong"><span class="T15">。</span></span><span class="T15">我们将及时将事件相关情况以App推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童个人信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将承担相应的法律责任。</span><span class="T6"/></p>

<p class="P10"><span class="T8">3、下列情形下，您或您监护的儿童可以通过本政策第十条中的联系方式与我们联系，要求删除相应的儿童个人信息：</span><span class="T6"/></p>

<p class="P10"><span class="T8">（1）我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、对外提供儿童个人信息的；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（2）超出目的范围或者必要期限收集、存储、使用、对外提供儿童个人信息的；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（3）您撤回同意的；</span><span class="T6"/></p>

<p class="P10"><span class="T8">（4）您或您的孩子通过注销等方式终止使用产品或者服务的。</span><span class="T6"/></p>

<p class="P5"><span class="Strong"><span class="T15">请您注意，绝大多数情形下我们无法识别且不会判断收集和处理的个人信息是否属于儿童个人信息，我们将按照本政策收集和处理用户的个人信息。</span></span><span class="T6"/></p>

<p class="P8"><span class="Strong"><span class="T8">八、联系我们</span></span><span class="T6"/></p>

<p class="P5"><span class="T8">您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</span><span class="T6"/></p>

<p class="P5"><span class="T8">1、如您对本政策内容有任何疑问、意见或建议，您可通过看看云医客服与我们联系；</span><span class="T6"/></p>

<p class="P10"><span class="T8">2、我们还设立了个人信息保护专职部门，您可以通过</span><span class="T13">kkyyylpt@163.com</span><span class="T8">与其联系。</span><span class="Strong"><span class="T8">需注意的是，我们可能不会回复与本政策或与个人信息权利无关的问题。</span></span><span class="T6"/></p>

<p class="P10"><span class="T8">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，</span><span class="Strong"><span class="T8">您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span></span><span class="T6"/></p>
<p class="P22"> </p>
<p class="P8"><span class="Strong"><span class="T8">附录：相关定义</span></span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">1、</span></span><span class="Strong"><span class="T3">看看云医/看看云医平台：</span></span><span class="T3">是一款</span><span class="T8">在线医疗产品，有网站、APP、小程序等多端入口</span><span class="T3">。</span><span class="Strong"><span class="T8">为了</span></span><span class="Strong"><span class="T3">更加便捷的给您提供服务，看看云医</span></span><span class="Strong"><span class="T8">各个端数据共享并同步。</span></span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">2、看看云医服务提供者：</span></span><span class="T3">指研发并提供看看云医产品和服务的法律主体云南柳叶刀医疗管理有限公司。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">3、个人信息：</span></span><span class="T8">指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，不包括匿名化处理后的信息。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">4、个人敏感信息：</span></span><span class="T8">指包括身份证件号码、生物识别信息、医疗健康信息、财产信息、行踪轨迹、交易信息、不满十四周岁未成年人信息等的个人信息（我们将在本政策中对具体个人敏感信息以</span><span class="Strong"><span class="T8">粗体</span></span><span class="T8">进行显著标识）。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">5、个人信息删除：</span></span><span class="T8">指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">6、儿童</span></span><span class="T8">：指不满十四周岁的未成年人。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">7、小程序/轻应用：</span></span><span class="T8">指看看云医服务提供者在微信/支付宝等开发的医疗健康频道、预约挂号、体检预约、心理健康、医护上门、专项检测、慢病管理、健康服务等多款小程序/轻应用。 </span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">8、设备信息：</span></span><span class="T8">包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI、SSID、BSSID、SIM 卡序列号（ICCID）、设备序列号及其他设备相关信息），应用信息（应用崩溃信息、通知开关状态、运行中进程、应用安装列表及其他应用相关信息），设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息），蓝牙信息，广播组件通讯信息，运营商信息，传感器（陀螺仪、重力传感器、加速度传感器、环境传感器、光学心率传感器），剪切板。以产品实际采集情况为准。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">9、服务日志信息：</span></span><span class="T8">包括浏览记录、点击查看记录、搜索查询记录、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">10、去标识化：</span></span><span class="T8">指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</span><span class="T6"/></p>

<p class="P10"><span class="Strong"><span class="T8">11、匿名化：</span></span><span class="T8">指个人信息经过处理无法识别特定自然人且不能复原的过程。</span><span class="T6"/></p>
<p class="Standard"> </p>

</div>

</template>

<style scoped>

table { border-collapse:collapse; border-spacing:0; empty-cells:show }
    td, th { vertical-align:top; font-size:12pt;}
    h1, h2, h3, h4, h5, h6 { clear:both;}
    ol, ul { margin:0; padding:0;}
    li { list-style: none; margin:0; padding:0;}
    span.footnodeNumber { padding-right:1em; }
    span.annotation_style_by_filter { font-size:95%; font-family:Arial; background-color:#fff000;  margin:0; border:0; padding:0;  }
    span.heading_numbering { margin-right: 0.8rem; }* { margin:0;}
    .P1 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0in; background-color:#ffffff; }
    .P10 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0in; text-indent:0.302in; background-color:#ffffff; }
    .P11 { font-size:12pt; margin-bottom:0.0472in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1508in; text-indent:0in; background-color:#ffffff; }
    .P12 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1508in; text-indent:0in; background-color:#ffffff; }
    .P13 { font-size:12pt; margin-bottom:0.0283in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.0846in; text-indent:0.302in; background-color:#ffffff; }
    .P14 { font-size:12pt; margin-bottom:0.0283in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.0846in; text-indent:0.1791in; background-color:#ffffff; }
    .P15 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0.302in; margin-right:0.1508in; text-indent:0in; background-color:#ffffff; }
    .P16 { font-size:12pt; margin-bottom:0in; margin-top:0in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.0846in; text-indent:0.2638in; background-color:#ffffff; }
    .P17 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0in; background-color:#ffffff; }
    .P18 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0.302in; background-color:#ffffff; }
    .P19 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0.2638in; background-color:#ffffff; }
    .P2 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0.2736in; background-color:#ffffff; }
    .P20 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0.2264in; margin-right:0.1228in; text-indent:0in; background-color:#ffffff; }
    .P21 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1508in; text-indent:0.302in; background-color:#ffffff; }
    .P22 { font-size:14pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Tahoma; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0in; text-indent:0.302in; background-color:#ffffff; }
    .P23 { font-size:10.5pt; margin-bottom:0in; margin-top:0in; text-align:center ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; }
    .P24 { font-size:14pt; margin-bottom:0in; margin-top:0in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; }
    .P3 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0.2638in; background-color:#ffffff; }
    .P4 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1228in; text-indent:0.302in; background-color:#ffffff; }
    .P5 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0in; text-indent:0.302in; background-color:#ffffff; }
    .P6 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0.2638in; margin-right:0.0846in; text-indent:0in; background-color:#ffffff; }
    .P7 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.0846in; text-indent:0in; background-color:#ffffff; }
    .P8 { font-size:12pt; margin-bottom:0.198in; margin-top:0.0756in; text-align:justify ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; background-color:#ffffff; }
    .P9 { font-size:12pt; margin-bottom:0in; margin-top:0.0756in; text-align:left ! important; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-left:0in; margin-right:0.1508in; text-indent:0in; background-color:#ffffff; }
    .Standard { font-size:10.5pt; font-family:Calibri; writing-mode:horizontal-tb; direction:ltr; margin-top:0in; margin-bottom:0in; text-align:justify ! important; }
    .Internet_20_link { color:#0000ff; text-decoration:underline; }
    .Strong { font-weight:bold; }
    .T1 { font-size:22pt; font-weight:bold; }
    .T10 { color:#000000; font-family:宋体; font-size:14pt; text-decoration:underline; background-color:#ffffff; }
    .T11 { color:#000000; font-family:宋体; font-size:14pt; font-weight:bold; background-color:#ffffff; }
    .T12 { color:#000000; font-family:Tahoma; font-size:14pt; background-color:#ffffff; }
    .T13 { color:#171a1d; font-family:宋体; font-size:14pt; background-color:#ffffff; }
    .T14 { color:#800080; font-family:宋体; font-size:14pt; text-decoration:none ! important; background-color:#ffffff; }
    .T15 { color:#333333; font-family:宋体; font-size:14pt; background-color:#ffffff; }
    .T2 { font-size:14pt; }
    .T3 { font-family:宋体; font-size:14pt; background-color:#ffffff; }
    .T5 { font-family:宋体; font-size:14pt; text-decoration:underline; background-color:#ffffff; }
    .T6 { font-family:Tahoma; font-size:14pt; }
    .T7 { font-family:Tahoma; font-size:14pt; }
    .T8 { color:#000000; font-family:宋体; font-size:14pt; background-color:#ffffff; }
    .T9 { color:#000000; font-family:宋体; font-size:14pt; background-color:#ffffff; }



</style>