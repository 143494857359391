<template>
  <div id="app">
    <!-- 在此处添加 <router-view> 来展示当前路由对应的组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0px;
  /* margin: -8px -8px auto; */
}
</style>
