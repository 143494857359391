<template>


 <img class="bg-root" src="../assets/img/bg_about_us.webp">

</template>

<script setup>


</script>

<style scoped>

.bg-root{
width: 100%;
aspect-ratio: 1.814;}


</style>