<template>
  <div>
    <!-- <el-image style="width: 100%; height: 50%; aspect-ratio: 2;" :src="url" :fit="fit" /> -->

    <!-- banner -->
    <el-carousel :interval="5000" class="banner-box" arrow="never">
      <el-carousel-item style="width: 100%;height: auto;" v-for="item in urls" :key="item">
        <img style="width: 100%;height: 100%; object-fit: cover;" :src="item">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

import bann1 from '../assets/img/img_home_banner1.webp'
export default {
  setup() {
    // const urls = [bann1,
    //   "https://cdn.pixabay.com/photo/2017/03/29/15/18/tianjin-2185510_1280.jpg",
    //   "https://cdn.pixabay.com/photo/2018/09/05/16/33/dna-3656587_1280.jpg",
    //   "https://cdn.pixabay.com/photo/2015/03/11/12/31/buildings-668616_1280.jpg"];

    const urls = [bann1];

    return {
      urls,
    };
  }
}
</script>

<style scoped>
.banner-box {
  width: 100%;
  aspect-ratio: 1.98;
  transition: 1s;
}
.banner-box:hover{
  transition: 0.8s;
 transform: scale(1.1);

}
</style>
