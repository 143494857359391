import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '@/assets/style/common.css';
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage.vue';
import PrivacyPage from './components/PrivacyPage.vue';
import ProductDetailPage from './components/ProductDetailPage.vue';


const routes=[
    {path:"/",component:HomePage},
{path:'/privacy',component:PrivacyPage},
{path:'/product_detail',component:ProductDetailPage}
];
const router =createRouter({
    history:createWebHistory(),
    routes
});


const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.mount('#app')