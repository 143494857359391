<template>
    <div class="bg-root">
        <TopBarView style="position: relative;z-index: 4;" @about-click="navigateToHome('about')"
            @product-click="navigateToHome('product')" @honor-click="navigateToHome('honor')"> </TopBarView>

        <div class="image-container">
            <img v-for="(image, index) in imgLists" :key="index" :src="image" class="image-item"
                alt="Image Description" />
        </div>


        <FootView @about-click="navigateToHome('about')"
        @product-click="navigateToHome('product')" @honor-click="navigateToHome('honor')"> </FootView>


    </div>

</template>

<script setup>

import { useRouter } from 'vue-router';
import {onMounted} from 'vue';

import FootView from './FootView.vue';
import TopBarView from './TopBarView.vue';
import img1 from '../assets/product/img_product_detail1.webp';
import img2 from '../assets/product/img_product_detail2.webp';
import img3 from '../assets/product/img_product_detail3.webp';
import img4 from '../assets/product/img_product_detail4.webp';
import img5 from '../assets/product/img_product_detail5.webp';
import img6 from '../assets/product/img_product_detail6.webp';
import img7 from '../assets/product/img_product_detail7.webp';
import img8 from '../assets/product/img_product_detail8.webp';
import img9 from '../assets/product/img_product_detail9.webp';

const imgLists = [img1, img2, img3, img4, img5, img6, img7, img8, img9];


const router = useRouter();

const navigateToHome = (section) => {
  router.push({ path: '/', query: { section } });
};


onMounted(() => {
  window.scrollTo(0, 0);
});

</script>

<style setup>
.bg-root {
    display: relative;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}

.image-item {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.5s;
}

.image-item:hover {
    transition: 0.8s;
    transform: scale(1.03);
}

</style>