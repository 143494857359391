<template>
<el-affix :offset="0" id="home">
      <div class="menu-bar" :style="{background:navBackground}" >

        <!-- 医院标志 -->
     <a class="menu-item" style="margin-left: 130px;" :style="{color:navColor}" href="#home">云南柳叶刀医疗管理有限公司</a>
      


        <!-- 导航栏 -->
        <div style=" margin-right: 140px;">
          <nav>
            <a class="menu-item" style="  margin-right: 100px;" :style="{color:navColor}" @click="handleAboutClick">关于我们</a>
            <a class="menu-item" style="  margin-right: 100px;" :style="{color:navColor}" @click="handleProductClick">产品展示</a>
            <a class="menu-item" :style="{color:navColor}" @click="handleHonorClick">资证荣誉</a>
          </nav>
        </div>

      </div>
    </el-affix>

</template>

<script>
import { ref } from 'vue';

export default {
  setup(props, { emit }) {

    const navBackground = ref('transparent');
    const navColor = ref('white');

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      console.log("子组件滚动距离：" + scrollTop);
      // 根据滚动距离动态改变导航栏背景色和文字颜色
       if(scrollTop<20){
        navBackground.value = 'transparent';
        navColor.value = 'white';
       }else{
        navColor.value = `rgba(31, 31, 31,${scrollTop /700})`;
        navBackground.value = `rgba(255, 255, 255,${scrollTop /700})`;
       }
    };

    // 监听页面滚动事件
    window.addEventListener('scroll', handleScroll);

    // 在组件销毁时移除滚动事件监听器
    const beforeUnmount = () => {
      window.removeEventListener('scroll', handleScroll);
    };

  //点击关于我们
 const handleAboutClick = () => {
      emit('about-click');
    };
   //点击产品介绍
    const handleProductClick = () => {
      emit('product-click');
    };
//点击资证荣誉
    const handleHonorClick = () => {
      emit('honor-click');
    };

    return {
      navBackground,
      navColor,
      beforeUnmount,
      handleAboutClick,
      handleProductClick,
      handleHonorClick,
    };


  }
}
</script>

<style scoped>

.menu-item {
  display: inline-block;
  font-size: 20px;
  white-space: nowrap;
  color: white;
  text-decoration: none;
}

.menu-bar {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0px;
  right: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
</style>