<template>
    <div class="bg-root">
        <TopBarView style="position: relative;z-index: 4;" @about-click="navigateToSection('about')"
            @product-click="navigateToSection('product')" @honor-click="navigateToSection('honor')"> </TopBarView>
        <HeadView style="position: relative; z-index: 1;"></HeadView>
        <AboutView id="about" ref="about" style="position: absolute;margin-top: 42.7%; top: 0px; z-index: 2;">
        </AboutView>

        <div
            style="position: absolute;margin-top: 37.8%;margin-left: 83%; top: 0px; z-index: 3;display: flex;flex-direction: column;justify-content: center;align-items: center; ">
            <a href="#about">
                <img class="modeCard" src="../assets/icon/ic_scroll_down.png" style="width: 27px;">
            </a>
            <div style="background-color: white;width: 1px;height: 72px;"></div>
            <span style="color: white;font-size: 12px;margin-top: 10px;">向下滑</span>
        </div>

        <ProductView id="product" ref="product" style="margin-top: 42.7%; top: 0px;"></ProductView>
        <HonorView id="honor" ref="honor"></HonorView>
        <FootView @about-click="navigateToSection('about')"
        @product-click="navigateToSection('product')" @honor-click="navigateToSection('honor')"></FootView>


    </div>

</template>

<script>
import {onMounted, watch } from 'vue';
import {useRoute } from 'vue-router';
import TopBarView from '@/components/TopBarView.vue';
import HeadView from '@/components/HeadView.vue';
import AboutView from '@/components/AboutView.vue';
import ProductView from '@/components/ProductView.vue';
import HonorView from '@/components/HonorView.vue';
import FootView from '@/components/FootView.vue';

export default {
  components: {
    TopBarView,
    HeadView,
    AboutView,
    ProductView,
    HonorView,
    FootView
  },
  setup() {
    const route = useRoute();

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Watch for route changes to handle scrolling
    watch(route, (newRoute) => {
      const section = newRoute.query.section;
      if (section) {
        scrollToSection(section);
      }
    });

    // Handle initial scroll if coming from a different route
    onMounted(() => {
      const section = route.query.section;
      if (section) {
        scrollToSection(section);
      }
    });

    return {
      scrollToSection
    };
  },
  methods: {
    navigateToSection(section) {
      this.$router.push({ path: '/', query: { section } });
    }
  }
};
</script>

<style setup>
.bg-root {
    display: relative;
}

.modeCard {
    transition: all 0.5s;
}

.modeCard:hover {

    transition: 0.8s;
    transform: scale(1.3);
}
</style>