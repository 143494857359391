<template>
    <div class="bg_root">


        <div class="menu-bar">
            <div style="display: flex;flex-direction:row;">

                <el-text class="menu-item" style="margin-left: 130px;color: white;">云南柳叶刀医疗管理有限公司</el-text>
            </div>

            <div style=" margin-right: 140px;">
                <nav>
                    <a class="menu-item" style="margin-right: 100px;" @click="handleAboutClick">|&nbsp;&nbsp;关于我们</a>

                    <a class="menu-item" style="  margin-right: 100px;" @click="handleProductClick">|&nbsp;&nbsp;产品展示</a>
                    <a class="menu-item" style="  margin-right: 100px;" @click="handleHonorClick">|&nbsp;&nbsp;资证荣誉</a>
                    <!-- <a class="menu-item"  href="https://www.jiguang.cn/license/privacy?from_wecom=1" target="_blank">|&nbsp;&nbsp;隐私政策</a> -->
              
                     <router-link to="/privacy" class="menu-item"  target="_blank">
                        <span> |&nbsp;&nbsp;隐私政策</span>
                       </router-link>  
                  

                   
                </nav>
            </div>

        </div>


        <div style="display: flex; flex-direction: row;padding-top: 47px;padding-bottom: 47px;margin-left: 130px;">

            <span class="menu-item" style="font-size: 14px;">
                ©2024云南柳叶刀医疗管理有限公司&nbsp;&nbsp;版权所有
            </span>

            <a class="menu-item" style="font-size: 14px;margin-left: 80px;" href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
                公安备案号：XXXXXX
            </a>
            <a class="menu-item" style="font-size: 14px;margin-left: 80px;" href="https://beian.miit.gov.cn/" target="_blank">
                IPC备案号：XXXXXX
            </a>

            <a class="menu-item" style="font-size: 14px;margin-left: 80px;" href="https://mpa.yn.gov.cn/" target="_blank" >
                互联网信息服务资格证证号：XXXXXXXX
            </a>
        </div>
    </div>
</template>

<script>


export default {
  setup(props, { emit }) {

  //点击关于我们
 const handleAboutClick = () => {
      emit('about-click');
    };
   //点击产品介绍
    const handleProductClick = () => {
      emit('product-click');
    };
//点击资证荣誉
    const handleHonorClick = () => {
      emit('honor-click');
    };

    return {
      handleAboutClick,
      handleProductClick,
      handleHonorClick,
    };


  }
}
</script>

<style scoped>
.bg_root {
    background-color: #0A294D;
    display: flex;
    flex-direction: column;
}

.menu-item {
    display: inline-block;
    font-size: 20px;
    white-space: nowrap;
    color: white;
    opacity: 0.7;
    text-decoration: none;
}

.menu-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 47px;
    padding-bottom: 47px;
    top: 0px;
    right: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}
</style>