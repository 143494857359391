<template>


  <div style="display: flex;flex-direction: column; padding-top: 170px;">

    <div style="position: relative; display: flex; flex-direction: column; justify-content: flex-end;">
      <img src="../assets/img/bg_product.png" style="width: 50%; height: auto; position: absolute; bottom: 0;">

      <div style="display: flex; flex-direction: column; align-items: center; z-index: 1;">
        <img src="../assets/img/img_product_title.png" style="width: 200px; height: auto;">
        <span style="color: #DBDBDB; font-size: 30px; margin-top: 25px;">PRODUCT INTRODUCTION</span>
      </div>
    </div>


    <div style="display: flex;flex-direction: column;align-items: center; margin-left: 160px;margin-right: 144px;">



      <div style="display: flex; flex-direction: row; justify-content: space-between;margin-top: 70px;">

        <div style="display: flex; flex-direction: column;">
          <span style="font-size: 34px;color: #191818;margin-top: 89px;">硅基动感持续葡萄糖监测系统</span>
          <span style="font-size: 16ppx;color: #DBDBDB; margin-top: 20px;">Silicon based dynamic continuous glucose
            monitoring system</span>
          <div class="diver"></div>
          <span style="font-size: 16px;color: #9F9F9F;margin-top: 44px;">使用硅基动感®CGM，可在手机查看实时、连续、全面的血糖数据和波动趋势接收血糖</span>
          <span style="font-size: 16px;color: #9F9F9F;">异常提醒，全程可实现免采血免扎手指，帮助您更好地管理血糖。</span>
        </div>

        <img class="modeCard" style="width: 37%;height: auto;margin-top: 30px;object-fit: cover;"
          src="../assets/img/img_product1.webp">
      </div>


      <div style="width: 100%;height: auto;display: flex; flex-direction: row; justify-content: space-between;">

        <div style="display: flex;flex-direction: column;margin-top: 100px;">
          <div style="height: auto;display: grid; grid-template-columns: repeat(1,1fr); gap: 20px;">
            <span v-for="item in products" :key="item" style="font-size:16px;color: #9F9F9F;">● {{ item }}</span>
          </div>

          <div class="bt_border" @click="isShowPayDialog = true">
            <span style="color:#155DC7;font-size: 20px;">付费查看产品介绍</span>
            <span class="circle-text">></span>

          </div>

        </div>


        <div style="width: 53.8%;height: auto; display: flex; flex-direction: row; align-items: flex-start;">
          <img class="modeCard" style="width:31%;object-fit: cover;" src="../assets/img/img_product2.webp">
          <img class="modeCard" style="width:32.6%;object-fit: cover;" src="../assets/img/img_product3.webp">
          <img class="modeCard" style="width:36.4%;object-fit: cover;" src="../assets/img/img_product4.webp">
        </div>
      </div>

    </div>

    <el-dialog v-model="isShowPayDialog" :show-close="false" style="border-radius: 12px;"
      :before-close="colsePayDialog">
      <div style="display: flex;flex-direction: column;">

        <div style="display: flex;flex-direction: row;">
          <span
            style="color: #303030;font-size: 20px;font-weight: bold;flex: 1;text-align: center;">了解完整的产品信息需要支付</span>
          <span class="circle-border-text" @click="closeDialog">x</span>
        </div>

        <div v-if="!isPaySuccess"
          style="border-radius: 12px;background-color: #F9F9F9; flex-direction: column;
        display: flex;margin-top: 39px;margin-left:30px;margin-right: 30px;margin-bottom: 30px; padding-left: 78px;padding-right:78px;padding-top: 30px;padding-bottom: 30px;">
          <span style="flex: 1;text-align: center;color: #FF3E3E; size: 20px;">应付金额：10元</span>

          <div style="display: flex;flex-direction: row;margin-top: 34px;">
            <img src="../assets/img/img_wechat_pay_code.webp" style="width: 226px;height: 226px;"
              @click="startPay">
            <div style="flex: 1;"></div>
            <img src="../assets/img/img_zfb_pay_code.webp" style="width: 226px;height: 226px;" @click="startPay">

          </div>

          <div style="display: flex; flex-direction: row;margin-top: 26px;">

            <img src="../assets/img/img_wechat_pay.webp" style="width:183px;height:auto;margin-left: 22px;"
              @click="isPaySuccess = true">
            <img src="../assets/img/img_zfb_pay.webp"
              style="width:183px;height:auto;margin-left: auto;margin-right:25px">


          </div>


        </div>

        <div v-if="isPaySuccess"
          style="border-radius: 12px;background-color: #F9F9F9; flex-direction: column;
        display: flex;justify-content: center;align-items: center;margin-top: 39px;margin-left:30px;margin-right: 30px;margin-bottom: 30px; padding-left: 78px;padding-right:78px;padding-top: 30px;padding-bottom: 30px;">
          <span style="flex: 1;text-align: center;color: #FF3E3E; size: 20px;">应付金额：10元</span>


          <img src="../assets/icon/ic_pay_success.png" style="width: 100px;height: 100px;margin-top: 40px;">

          <span style="font-size:18px;font-weight:bold;color:#303030;margin-top: 32px;">支付成功</span>

          <span style="font-size: 18px;color:#303030;margin-top: 25px;">{{countdown}}秒后自动跳到产品信息</span>



        
            <span @click="toProdectDetail" style="border-radius: 8px;border: 1px solid #CBCBCB;padding: 5px; margin-top: 40px;">查看产品信息</span>
      




        </div>




      </div>



    </el-dialog>

  </div>



</template>


<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter();
const countdown = ref(10);
let countdownInterval;

const isShowPayDialog = ref(false);

const isPaySuccess = ref(false);

const products = ["免扎手指，不用校准，轻松测探", "高低血糖异常提醒",
  "14天效期，实时监控，日夜守护", "血糖数据一键分享，医院/亲友共同管理",
  "ipx8级防水，可浸入水下1米60分钟"];


  function startPay(){
    isPaySuccess.value = true;
    startCountdown();
  }

const colsePayDialog = (done) => {
  isPaySuccess.value = false;
  if(countdownInterval){
    clearInterval(countdownInterval);
  }
  done();
}

function closeDialog() {
  isPaySuccess.value = false;
  isShowPayDialog.value = false;
  if(countdownInterval){
    clearInterval(countdownInterval);
  }
}


function toProdectDetail(){
  if(countdownInterval){
    clearInterval(countdownInterval);
  }
  router.push('/product_detail');
}


const startCountdown = () => {
      countdown.value = 10; // Reset countdown
      countdownInterval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value -= 1;
        } else {
          toProdectDetail();
        }
      }, 1000);
    };


 
</script>


<style scoped>
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 78px;
}

.product-item {
  display: flex;
  flex-direction: column;
}

.product-info {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 20px 20px;
  background-color: #2B4297;
  padding: 30px;
}

.product-info-grid-item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
  row-gap: 20px;
  margin-top: 40px;
}


.product-info-grid-item-span {
  color: white;
  font-size: 14px;
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}

.modeCard {
  transition: all 0.5s;
}

.modeCard:hover {
  transition: 0.8s;
  transform: scale(1.1);
}

.diver {
  border-bottom: 1px solid rgba(151, 151, 151, 0.23);
  margin-top: 44px;
  border-width: 1px;
  width: 32%p;
}

.bt_border {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 234px;
  height: 70px;
  margin-top: 100px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #155DC7;
}

.circle-text {
  color: white;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #155DC7;
}

.circle-border-text {
  color: #dadbdb;
  width: 25px;
  height: 25px;
  font-size: 20px;
  margin-left: auto;
  margin-right: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #dadbdb;
}
</style>